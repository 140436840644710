/* eslint-disable no-console */

import React, { useState, useEffect } from 'react';
import styles from './index.module.scss';
import { ArticleInterface, ArticlesContainerInterface } from './store';

interface Props {
  articles: ArticleInterface[];
  articleContainer: ArticlesContainerInterface[];
}

export const Articles: React.FC<Props> = ({ articles, articleContainer }) => {
  const [contents, setContents] = useState<ArticleInterface[]>([]);

  useEffect(() => {
    const articleGroup: ArticleInterface[] = articles.map((item) => ({
      ...item,
    }));
    setContents(articleGroup);
  }, [articles]);

  const RenderList: React.FC<{ content: ArticleInterface }> = ({
    content: {
      url,
      target,
      title,
      thumbnail,
      bgColor,
      fontColor,
      subtitleFontColor,
      category,
      readTime,
    },
  }) => {
    return (
      <div className={styles.postContainer}>
        <a
          href={url}
          target={target ?? '_self'}
          rel="noreferrer"
          className={styles.post}
          style={{
            backgroundColor: bgColor !== '' ? `#${bgColor}` : '#f2f3f4',
          }}
        >
          <div className={styles.imageHolder}>
            <img src={thumbnail} alt={title} />
          </div>
          <div className={styles.content}>
            <div
              className={styles.contentTitle}
              style={{
                color: fontColor !== '' ? `#${fontColor}` : '#323334',
              }}
              dangerouslySetInnerHTML={{ __html: title || '' }}
            />
            <span
              className={styles.timeShared}
              style={{
                color:
                  subtitleFontColor !== ''
                    ? `#${subtitleFontColor}`
                    : '#323334',
              }}
            >
              {category} {readTime && <>&nbsp;|&nbsp; {readTime}</>}
            </span>
          </div>
        </a>
      </div>
    );
  };

  const { showTitle, titleHeader, tileBackgroundColor, titleFontColor } =
    articleContainer?.[0] || {};

  return articleContainer.length > 0 ? (
    <div
      id="article-blocks"
      className={styles.article_container}
      style={{
        backgroundColor:
          tileBackgroundColor !== '' ? `#${tileBackgroundColor}` : '#646566',
      }}
    >
      <div
        className={styles.headline}
        style={{
          padding: showTitle ?? '0px',
          color: `#${titleFontColor}` ?? '#323334',
        }}
        dangerouslySetInnerHTML={{ __html: showTitle ? titleHeader : '' }}
      />
      <div className={styles.posts}>
        {contents?.map((content, index) => (
          <RenderList key={index} content={content} />
        ))}
      </div>
      <div className={styles.footer} />
    </div>
  ) : null;
};

export default React.memo(Articles);
